import React from "react";
import { Route, Redirect } from "react-router-dom";
import ProtectedRouteController from "./ProtectedRouteController.web";

export default class ProtectedRoute extends ProtectedRouteController {
  render() {
    return !this.state.loading ? (
      this.state.token === "" ? (
        <Redirect to="/login" />
      ) : (
        <Route {...this.props} />
      )
    ) : null;
  }
}
