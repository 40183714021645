// App.js - WEB
import React, { Suspense, lazy } from "react"
import AppController from "./AppController.web"
import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTopButton from "react-scroll-to-top"
import HomeScreen from "../../../components/src/HomeScreen"
import InfoPage from "../../info-page/src/InfoPageBlock"
import AlertBlock from "../../alert/src/AlertBlock.web"
import { ToastContainer } from "react-toastify"
import WebRoutes from "./WebRoutes"
import { withRouter } from "react-router-dom"
import Helmet from "react-helmet"
import { withTranslation } from "react-i18next"
import "../../internationalization/src/i18n"


const routeMap = {
  Home: {
    component: HomeScreen,
    path: "/",
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: "/InfoPage",
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true,
  },
}

class App extends AppController {
  render() {
    const renderHelmet = () => (
      <Helmet htmlAttributes={{ lang : this.props?.i18n?.language || "en", dir: this.props?.i18n.language === "ar" ?  "rtl" : "ltr" }}>
        {this.state?.title ? <title>{this.state?.title}</title> : <title>E-store Demo</title>}
        <link rel="icon" href={this.state.favicon || "/favicon.ico"} sizes="16x16" />
      </Helmet>
    )
    if (!this.state.isAuthenticated) {
      return null
    }

    return (
      <>
        {renderHelmet()}
        <div
          className="d-flex flex-column"
          style={{ minHeight: "100%" }}
        >
          <WebRoutes />
          {!this.state.isTawkToPresent && <ScrollToTopButton smooth top={200} />}
          <ToastContainer
            rtl={this.props?.i18n.language === "ar"}
          />

        </div>
      </>
    )
  }
}

export default withTranslation()(withRouter(App))
