const ar = {
  components: {
    homePage: {
      viewAll: "عرض الكل",
      categories: "الفئات",
      newArrivals: "الوصول الجديد",
      trendingProducts: "المنتجات الشائعة",
      noProductsFound: "لم يتم العثور على منتجات.",
      copyright: "حقوق النشر © 2020 Studio Store ، الولايات المتحدة الأمريكية. كل الحقوق محفوظة.",
      headerShop: "متجر",
      headerNewArrivals: "الوصول الجديد",
      searchPlaceholder: "يبحث",
      noResultsFound: "لم يتم العثور على نتائج",
      tryModifyingYourSearchToGetRelevantResults: "حاول تعديل بحثك للحصول على نتائج ذات صلة",
      goToMyAccount: "اذهب إلى حسابي",
      myWishlist: "قائمة امنياتي",
      myOrders: "طلباتي",
      profileAndSecurity: "الملف الشخصي والأمان",
      myAddresses: "عناويني",
      helpCenter: "مركز المساعدة",
      socialAccounts: "حسابات التواصل الاجتماعي",
      signOut: "خروج",
      notifications: "الإشعارات",
    },
    dashboard:{
      description: "الوصف",
      addToCart: "أضف إلى السلة",
      goToCart: "اذهب إلى السلة",
      subscribed: "مشترك",
      subscribe: "الإشتراك",
      buyNow: "اشتري الآن",
      inStockOnline: "متوفر في المخزون عبر الإنترنت",
      soldOut: "نفذت الكمية",
      weLlNotifyYou: "سنخطرك!",
      weLlNotifyYouMessage: "لقد تلقينا طلبك ، وبمجرد عودة هذا المنتج إلى المخزون ، سنرسل لك بريدًا إلكترونيًا",
      price: "السعر",
      quantity: "الكمية",
      outOfStock: "نفذت الكمية",
      off: "% خصم",
      updateCart: "تحديث السلة",
      totalPrice: "السعر الكلي",
      home: "الرئيسية",
      shop: "المتجر",
      viewAll: "عرض الكل",
      similarProducts: "منتجات مماثلة",
      notifyMe: "يخطر لي عندما تكون متاحة",
      reviews: "التعليقات",
      willNotify: "ستتلقى إشعارًا بمجرد عودة المنتج إلى المخزون",
    },
    filterItems: {
      clear: "مسح",
      price: "السعر",
      noResultsFound: "لم يتم العثور على نتائج",
      updateOrRemoveSomeOfTheFiltersYouUsed: "قم بتحديث أو إزالة بعض المرشحات التي استخدمتها",
      min: "الحد الأدنى",
      max: "الحد الأقصى",
      enterMinimumValue: "أدخل القيمة الدنيا",
      enterMaximumValue: "أدخل القيمة القصوى",
      apply: "تطبيق",
      discounts: "خصومات",
      discountedItems: "العناصر المخفضة",
      clearAll: "امسح الكل",
      kg: "كجم",
      material: "مواد",
      size: "بحجم",
      color: "اللون",
      brand: "العلامة التجارية",
      category: "الفئة",
      allProduct: "جميع المنتجات",
      lowtoHigh: "من الأقل إلى الأعلى",
      hightoLow: "من الأعلى إلى الأقل",
      byPopularity:   "حسب الشعبية",
      byNewest:   "حسب الأحدث",
      recommended: "موصى به",
      sort: "ترتيب حسب",
      home: "الصفحة الرئيسية",
      shop: "متجر",
      itemAddedToWishlist: "تمت إضافة العنصر إلى قائمة الرغبات",
      itemRemovedFromWishlist: "تمت إزالة العنصر من قائمة الامنيات",
      maximumValueShouldBeGreaterThanMinimumValue: "يجب أن تكون القيمة القصوى أكبر من القيمة الدنيا",
      minimumAndMaximumShouldNotBeSame: "يجب ألا تكون القيمة الدنيا والقصوى هي نفسها!",
      loadingProducts: "تحميل المنتجات",
      filter: "منقي",
      searchColor: "البحث عن اللون",
    },
    scheduling: {
      addToCart: "أضف إلى السلة",
      goToCart: "اذهب إلى السلة",
      subscribed: "مشترك",
      subscribe: "الإشتراك",
      buyNow: "اشتري الآن",
      price: "السعر",
      quantity: "الكمية",
      outOfStock: "نفذت الكمية",
      off: "% خصم",
      updateCart: "تحديث السلة",
      totalPrice: "السعر الكلي",
      subscription: "الإشتراك",
      subscriptionPeriod: "اختر فترة الاشتراك",
      subscriptionTimeslot: "الوقت المفضل",
      packageIsRequired: "الحزمة مطلوبة.",
      periodIsRequired: "الفترة مطلوبة.",
      timePeriodIsRequired: "الفترة الزمنية مطلوبة.",
      timeSlotIsRequired: "الفترة الزمنية مطلوبة.",
    },
    login: {
      signIn: "تسجيل الدخول",
      login: "تسجيل الدخول",
      continueVia: "تابع عبر ",
      bySigningIn: "بالتسجيل ، فإنك توافق على",
      termNcondition: "الشروط والأحكام",
      privacyPolicy: "سياسة الخصوصية",
      getStarted: "ابدأ واكتشف أفضل العروض من حولك",
      forgotPassword: "هل نسيت كلمة المرور ؟",
      signup: "سجل",
      emailPlaceholder: "البريد الإلكتروني",
      emailPhonePlaceholder: "البريد الإلكتروني / رقم الهاتف",
      passwordPlaceholder: "كلمة المرور",
      nameIsTooShort: "الاسم قصير جدا",
      nameIsRequired: "الاسم مطلوب",
      enterValidEmail: "الرجاء إدخال عنوان بريد إلكتروني صالح",
      emailIsRequired: "البريد الإلكتروني مطلوب",
      passwordValidation: "يجب أن تحتوي كلمة المرور على حرف كبير وحرف صغير ورقم وحرف خاص و 8 أحرف على الأقل",
      passwordIsRequired: "كلمة المرور مطلوبة",
      invalidEmailPhoneNumber: "البريد الإلكتروني / رقم الهاتف غير صالح",
      emailPhoneNumberIsRequired: "البريد الإلكتروني / رقم الهاتف مطلوب",
      errorOccurredWhileConnectingToFacebook: "حدث خطأ أثناء الاتصال بـ Facebook ، يرجى المحاولة مرة أخرى لاحقًا",
      errorOccurredWhileConnectingToGoogle: "حدث خطأ أثناء الاتصال بـ Google ، يرجى المحاولة مرة أخرى لاحقًا",
      sorryYouNeedToConfirmYourAccountFirst: "عذرًا ، تحتاج إلى تأكيد حسابك أولاً.",
      minimumPasswordLengthIs8: "الحد الأدنى لطول كلمة المرور هو 8",
      maximumPasswordLengthIs_16: "الحد الأقصى لطول كلمة المرور هو 16",
      passwordShouldIncludeAtLeastOneCapitalLetter: "يجب أن تتضمن كلمة المرور حرفًا كبيرًا واحدًا على الأقل"
    },
    register: {
      bySigningIn: "بالتسجيل ، فإنك توافق على",
      termNcondition: "الشروط والأحكام",
      privacyPolicy: "سياسة الخصوصية",
      getStarted: "ابدأ واكتشف أفضل العروض من حولك",
      signupVia: "أو سجل عبر",
      passwordSuggestionMsg: "أدخل كلمة مرور تحتوي على أحرف A-z وأرقام 0-9 ورموزًا",
      signup: "سجل",
      minimumPasswordLengthIs8: "الحد الأدنى لطول كلمة المرور هو 8",
      fullNamePlaceholder: "الاسم الكامل",
      emailPlaceholder: "البريد الإلكتروني",
      emailPhonePlaceholder: "البريد الإلكتروني / رقم الهاتف",
      passwordPlaceholder: "كلمة المرور",
      nameIsTooShort: "الاسم قصير جدا",
      nameIsRequired: "الاسم مطلوب",
      enterValidEmail: "الرجاء إدخال عنوان بريد إلكتروني صالح",
      emailIsRequired: "البريد الإلكتروني مطلوب",
      passwordValidation: "يجب أن تحتوي كلمة المرور على حرف كبير وحرف صغير ورقم وحرف خاص و 8 أحرف على الأقل",
      passwordIsRequired: "كلمة المرور مطلوبة",
      invalidEmailPhoneNumber: "البريد الإلكتروني / رقم الهاتف غير صالح",
      emailPhoneNumberIsRequired: "البريد الإلكتروني / رقم الهاتف مطلوب",
      errorOccurredWhileConnectingToFacebook: "حدث خطأ أثناء الاتصال بـ Facebook ، يرجى المحاولة مرة أخرى لاحقًا",
      errorOccurredWhileConnectingToGoogle: "حدث خطأ أثناء الاتصال بـ Google ، يرجى المحاولة مرة أخرى لاحقًا",
    },
    connectedAccounts: {
      areYouSureYouWantToDisconnectYour: "هل أنت متأكد أنك تريد فصل",
      accountFrom: "حساب",
      errorOccurredFacebook: "حدث خطأ أثناء الاتصال بـ Facebook",
      errorOccurredGoogle: "حدث خطأ أثناء الاتصال بـ Google",
      somethingWentWrong: "هناك خطأ ما",
      addedGoogleAccount: "تمت إضافة حساب Google بنجاح.",
      addedFacebookAccount: "تمت إضافة حساب Facebook بنجاح.",
      removedGoogleAccount: "تمت إزالة حساب Google بنجاح.",
      removedFacebookAccount: "تمت إزالة حساب Facebook بنجاح.",
      cancel: "إلغاء",
      disconnect: "قطع الاتصال",
      connectedAs: "متصل ك",
      connectFacebook: "توصيل حساب Facebook",
      connectGoogle: "توصيل حساب Google",
    },
    contactUs: {
      errorTitle: "خطأ",
      errorAllFieldsAreMandatory: "جميع الحقول إلزامية.",
      errorEmailNotValid: "البريد الإلكتروني غير صالح.",
      phoneNumberError: "رقم الهاتف غير صالح",
      nameTitle: "اسم",
      emailTitle: "البريد الإلكتروني",
      numberTitle: "رقم الهاتف",
      commentsTitle: "تعليقات",
      namePlaceHolder: "اسم",
      emailPlaceHolder: "البريد الإلكتروني",
      numberPlaceHolder: "رقم الهاتف",
      commentsPlaceHolder: "تعليقات",
      labelTitleText: "اتصل بنا",
      labelBodyText: "اتصل بنا الجسم",
      btnExampleTitle: "انقر هنا",
      home: "الصفحة الرئيسية",
      aboutUs: "معلومات عنا",
      customerReviews: "آراء العملاء",
      contactUs: "اتصل بنا",
      biggerSign: ">",
      somethingWentWrong: "هناك خطأ ما",
      readLess: "أقل قراءة",
      readMore: "قراءة المزيد",
      whatOurCustomersSay: "ماذا يقول عملاؤنا ...",
      successBlockTitle: "تم إرسال الرسالة بنجاح",
      successBlockMessage: "سنتصل بك قريبًا بشأن استفسارك.",
      subjectTitle: "موضوع",
      messageTitle: "رسالة",
      send: "إرسال",
      writeYourMessageHere: "اكتب رسالتك هنا ...",
      goToShop: "الذهاب إلى المتجر",
      goToHome: "الذهاب إلى الصفحة الرئيسية",
      minimum_3CharactersAreRequired: "الحد الأدنى 3 أحرف مطلوبة",
      maximum_20CharactersAreAllowed: "يسمح بحد أقصى 20 حرفًا.",
      nameIsRequired: "الاسم مطلوب.",
      onlyAlphabetsAreAllowed: "يسمح فقط بالأحرف الأبجدية.",
      pleaseEnterAValidEmailAddress: "الرجاء إدخال عنوان بريد إلكتروني صالح.",
      emailIsRequired: "البريد الإلكتروني مطلوب.",
      onlyNumbersAreAllowed: "يسمح فقط بالأرقام.",
      negativeNumbersAreNotAllowed: "لا يسمح بالأرقام السالبة.",
      phoneCanTContainADecimal: "لا يمكن أن يحتوي الهاتف على عشري.",
      phoneNumberIsMissing: "رقم الهاتف مفقود.",
      messageIsRequired: "الرسالة مطلوبة.",
      titleIsRequired: "العنوان مطلوب.",
      information: "معلومات",
    },
    forgotPassword: {
      skipAsGuest: "تخطى وتابع كضيف",
      otpHasBeenSent: "تم إرسال OTP إلى معرف البريد الإلكتروني الخاص بك.",
      otpHasBeenSenPhone: "تم إرسال OTP إلى رقم هاتفك.",
      forgotPasswordContentTextWithPhone:
        "أدخل بريدك الإلكتروني / رقم الهاتف المسجل وسنرسل لك OTP لإعادة تعيين كلمة المرور الخاصة بك",
      forgotPasswordContentText:
        "أدخل بريدك الإلكتروني المسجل وسنرسل لك OTP لإعادة تعيين كلمة المرور الخاصة بك",
      getStarted: "ابدأ واكتشف أفضل العروض من حولك",
      emailPlaceholder: "البريد الإلكتروني",
      emailPhonePlaceholder: "البريد الإلكتروني / رقم الهاتف",
      passwordPlaceholder: "كلمة المرور",
      invalidEmailOrPhone: "بريد إلكتروني / رقم هاتف غير صالح.",
      invalidEmail: "بريد إلكتروني غير صالح.",
      emailMustBeValid: "يجب أن يكون البريد الإلكتروني صالحًا",
      emailOrPhoneMustBeValid: "يجب أن يكون البريد الإلكتروني / رقم الهاتف صالحًا",
      pleaseEnterOTP: "الرجاء إدخال OTP",
      pleaseEnterMax6DigitOTP: "الرجاء إدخال OTP المكون من 5 أرقام على الأقل و 6 أرقام كحد أقصى",
      invalidOTP: "OTP غير صالح",
      verifyOtp: "تحقق من OTP",
      sendOtp: "إرسال OTP",
      otpVerification: "تحقق من OTP",
      forgotPassword: "هل نسيت كلمة المرور؟",
      createNewPassword: "إنشاء كلمة مرور جديدة",
      newPasswordValidationMessage: "يجب أن تحتوي كلمة المرور على حرف كبير وحرف صغير ورقم وحرف خاص",
      passwordIsRequired: "كلمة المرور مطلوبة",
      newPasswordIsRequired: "مطلوب كلمة مرور جديدة",
      confirmPasswordIsRequired: "تأكيد كلمة المرور مطلوب",
      required: "مطلوب",
      passwordShouldIncludeAtLeastOneCapitalLetter:
        "يجب أن تتضمن كلمة المرور حرفًا كبيرًا واحدًا على الأقل",
      newPassword: "كلمة مرور جديدة",
      confirmPassword: "تأكيد كلمة المرور",
      resendOTP: "إعادة إرسال رمز OTP",
      updatePassword: "تحديث كلمة المرور",
      tooShort: "قصير جدا",
      passwordsMustMatch: "يجب أن تتطابق كلمات المرور",
    },
    helpCenter: {
      home: "الصفحة الرئيسية",
      profile: "الملف الشخصي",
      helpCenter: "مركز المساعدة",
      welcomeToTheHelpCenter: "مرحبًا بك في مركز المساعدة، {{userName}}",
      FAQs: "الأسئلة الشائعة",
      information: "معلومات",
    },
    notifications: {
      deleteAll: 'حذف الكل',
      readAll: 'قراءة الكل',
      deleteNotificationTitleMessage: 'حذف الإشعار',
      deleteNotificationContentMessage: 'هل أنت متأكد أنك تريد حذف الإشعار؟',
      continueShoppingButton: 'متابعة التسوق',
      loadMore: 'تحميل المزيد',
      noNotificationsTitle: 'لا توجد إشعارات بعد!',
      noNotificationsMessage: 'تصفح المنتجات أو تحقق من أحدث إضافاتنا.',
      cancel: 'إلغاء',
      yesDelete: 'نعم ، احذف',
      notificationReadSuccessfully: "تم قراءة الإشعار بنجاح !!!",
      readNotificationIsPending: "قراءة الإشعار معلقة !!!",
      deleteNotification : "حذف الإشعار!",
    },
    orderDetailView: {
      subscription: 'اشتراك',
      totalSubscriptionPrice: 'إجمالي سعر الاشتراك',
      cancelSubscription: 'إلغاء الاشتراك',
      cancelSubscriptionQuestion: 'هل أنت متأكد أنك تريد إلغاء الاشتراك',
      yourOrderIs: "طلبك هو",
      quantity: 'الكمية',
      new: 'جديد',
      orderReadyToBePickedUp: "الطلب جاهز للاستلام",
      myOrders: "طلباتي",
      orderDetails: "تفاصيل الطلب",
      orderNumber: "رقم الطلب",
      shippingAddress: "عنوان الشحن",
      phoneNumber: "رقم الهاتف",
      myDotTransitMe: "myDot العبور لي",
      myDotSuccessMe: "نجاح myDot-me",
      myDotCancelMe: "myDot إلغاء لي",
      month_one: "شهر",
      month_other: "أشهر",
      packageInfo: "{{subscriptionPackage}} لمدة {{subscriptionPeriod}}",
      noAddressFound: "لم يتم العثور على عنوان",
      placed: 'تم الطلب',
      payment_failed: "فشل الدفع",
      payment_pending: "في انتظار الدفع",
      confirmed: "تم التأكيد",
      delivered: "تم التوصيل",
      cancelled: "تم الإلغاء",
      refunded: "تم استرداد المبلغ",
      returned: "تم الإرجاع",
      in_cart: "في السلة",
      created: "تم الإنشاء",
      in_transit: "في الانتقال",
      processing: "قيد المعالجة",
      orderStatus: "حالة الطلب",
      totalAmount: "المبلغ الإجمالي",
      orderedOn: "تم الطلب في",
    },
    orderManagement: {
      browseProducts: 'تصفح المنتجات',
      noAnyOrder: "لم يتم العثور على أي طلبات",
      browseItemNOrderIt: "لم تطلب أي عناصر ، تصفح العناصر واطلبها",
      rateNReview: "تقييم ومراجعة",
      rateOurProduct: "قيم منتجنا",
      youCannotReview: "لا يمكنك مراجعة المنتج حتى تشتريه.",
      writeDetailedReviewForUs: "اكتب مراجعة مفصلة لنا ..",
      areYouSureCancelOrder: "هل أنت متأكد أنك تريد إلغاء الطلب؟",
      cancelOrder: "إلغاء الطلب",
      subscription: 'اشتراك',
      totalSubscriptionPrice: 'إجمالي سعر الاشتراك',
      writeAReview: "اكتب مراجعة",
      TotalAmount: "المبلغ الإجمالي",
      orderedOn: "تم الطلب في",
      orderNumber: "رقم الطلب",
      morning: "صباحا",
      evening: "مساء",
      month_one: "شهر",
      month_other: "أشهر",
      thankYouForYourReview: "شكرا لك على مراجعتك",
      quantity: "الكمية",
      confirmed: "تم التأكيد",
      delivered: "تم التوصيل",
      cancelled: "تم الإلغاء",
      refunded: "تم استرداد المبلغ",
      returned: "تم الإرجاع",
      in_cart: "في السلة",
      created: "تم الإنشاء",
      in_transit: "في الانتقال",
      processing: "قيد المعالجة",
      placed: "تم الطلب",
      ratingIsRequired: "التقييم مطلوب.",
      commentIsRequired: "التعليق مطلوب.",
    },
    orderSummary: {
      couponCodeCanTBeEmpty: "لا يمكن أن يكون رمز القسيمة فارغًا",
      enterYourPromotionCode: "أدخل رمز الترويج الخاص بك",
      discount: "خصم",
      orderSummary: "ملخص الطلب",
      ohNoesTransactionFailed: "أوه لا!, فشلت العملية",
      yourOrderWasDeclined: "تم رفض طلبك!",
      changeThePaymentMethod: "تغيير طريقة الدفع",
      cancelTransaction: "إلغاء العملية؟",
      thankYou: "شكرا لك",
      orderPlacedSuccessfully: "تم تأكيد الطلب بنجاح",
      continueShopping: "متابعة التسوق",
      viewOrder: "عرض الطلب",
    },
    otpConfirmation: {
      verifyOTP: "تحقق من رمز OTP",
      resendOTP: "إعادة إرسال رمز OTP",
      otpVerification: "تحقق من رمز OTP",
      pleaseEnterOTP: "يرجى إدخال رمز OTP",
      pleaseEnterMax6DigitOTP: "يرجى إدخال رمز OTP من 5 إلى 6 أرقام",
      invalidOTP: "رمز OTP غير صالح",
      otpHasBeenSent: "تم إرسال رمز OTP إلى عنوان بريدك الإلكتروني.",
      otpHasBeenSenPhone: "تم إرسال رمز OTP إلى رقم هاتفك.",
      sendOtp: "إرسال رمز OTP",
      invalid_otp: 'يرجى العودة والمحاولة مرة أخرى.',
      getStarted: "ابدأ واكتشف أفضل العروض من حولك",
      otpPlaceholder: "أدخل رمز OTP",
      fashionAndTrendComeTogather: "الأزياء والاتجاه يجتمعان",
      upto30Off: "تصل الى 30% خصم",
      shopNow: "تسوق الآن",
      onlyNumbersAreAllowed: "الأرقام فقط مسموح بها",
      negativeNumbersAreNotAllowed: "الأرقام السلبية غير مسموح بها",
      numberCanTContainADecimal: "الأرقام لا يمكن أن تحتوي على عشري",
      minimum_5DigitsAreRequired: "الحد الأدنى 5 أرقام مطلوبة",
      maximum_6DigitsAreAllowed: "الحد الأقصى 6 أرقام مسموح بها",
      otpIsRequired: "رمز التحقق مطلوب.",
    },
    payments: {
      cardDetails: "تفاصيل البطاقة",
      cardNumber: "رقم البطاقة",
      cardHolderName: "اسم صاحب البطاقة",
      expirationDate: "تاريخ الانتهاء",
      cvv: "CVV",
      proceedToPay: "المتابعة للدفع",
    },
    profileBio: {
      logout: 'تسجيل الخروج',
      cancel: 'إلغاء',
      wantTologout: 'هل أنت متأكد أنك تريد تسجيل الخروج من',
      notification: 'الإشعارات',
      helpCenter: "مركز المساعدة",
      connectedAccounts: "الحسابات المتصلة",
      savedAddresses: "العناوين المحفوظة",
      myOrders: "طلباتي",
      wishlist: "قائمة الرغبات",
      loadMore: "تحميل المزيد",
      edit: "تعديل",
      delete: "حذف",
      addNewAddress: "إضافة عنوان جديد",
      noAddressesSaved: "لا توجد عناوين محفوظة!",
      addAddress: "إضافة عنوان",
      noAddressesList: "لم يتم حفظ أي عناوين في قائمة العناوين بعد!",
      name: "الاسم",
      flatHouseApartment: "رقم الشقة / البيت / الشقة",
      addressLine1: "العنوان الأول",
      addressLine2: "العنوان الثاني",
      city: "المدينة",
      state: "المحافظة",
      country: "الدولة",
      pinCode: "الرمز البريدي",
      phoneNumber: "رقم الهاتف",
      saveAddress: "حفظ العنوان",
      editAddress: "تعديل العنوان",
      deleteAddress: "حذف العنوان",
      areYouSureDeleteAddress: "هل أنت متأكد أنك تريد حذف العنوان؟",
      yesDelete: "نعم، حذف",
      defaultAdreess: "العنوان الافتراضي",
      defaultaddressContent: "تم تغيير العنوان الافتراضي الخاص بك.",
      okay: "موافق",
      goToProfile: "الانتقال إلى الملف الشخصي",
      goBackNBrowse: "يمكنك الآن العودة والاستمرار في تصفح المنتجات. استمتع بالتسوق!",
      passwordChangedSuccessfully: "تم تغيير كلمة المرور بنجاح!",
      changePassword: "تغيير كلمة المرور",
      reEnterNewPassword: "إعادة إدخال كلمة المرور الجديدة",
      newPassword: 'أدخل كلمة مرور جديدة',
      enterCurrentPassword: 'أدخل كلمة المرور الحالية',
      enterPasswordWithAlpha: "أدخل كلمة المرور بحروف A-Z وأرقام 0-9 ورموز",
      saveProfile: "حفظ الملف الشخصي",
      phoneNo: "رقم الهاتف",
      email: "البريد الإلكتروني",
      removePicture: "إزالة الصورة",
      editProfile: "تعديل الملف الشخصي",
      home: "الرئيسية",
      profile: "الملف الشخصي",
      errorOccured: "عذراً، حدث خطأ",
      nameIsTooShort: "الاسم قصير جداً",
      maximum_20CharactersAreAllowed: "يُسمح بحد أقصى 20 حرفًا",
      nameIsRequired: "الاسم مطلوب",
      flatHouseApartmentNoIsRequired: "رقم الشقة / البيت / الشقة مطلوب",
      addressLine_1IsTooShort: "العنوان الأول قصير جداً",
      addressLine_1IsRequired: "العنوان الأول مطلوب",
      addressLine_2IsTooShort: "العنوان الثاني قصير جداً",
      cityIsTooShort: "المدينة قصيرة جداً",
      cityIsRequired: "المدينة مطلوبة",
      stateIsRequired: "المحافظة مطلوبة",
      pinCodeIsRequired: "الرمز البريدي مطلوب",
      phoneNumberIsRequired: "رقم الهاتف مطلوب",
      svgFileAreNotAllowed: "لا يُسمح بملفات .svg",
      currentPasswordIsRequired: "كلمة المرور الحالية مطلوبة",
      minimumPasswordLengthIs_8: "الحد الأدنى لطول كلمة المرور هو 8",
      maximumPasswordLengthIs_16: "الحد الأقصى لطول كلمة المرور هو 16",
      passwordMustContainAtleastACapital: "يجب أن تحتوي كلمة المرور على حرف كبير على الأقل، وحرف صغير على الأقل، ورقم وحرف خاص.",
      newPasswordIsRequired: "كلمة المرور الجديدة مطلوبة",
      passwordsAreNotMatching: "كلمات المرور غير متطابقة",
      confirmPasswordIsRequired: "تأكيد كلمة المرور مطلوب",
    },
    shoppingCart: {
      qty: "الكمية",
      orderSummary: "ملخص الطلب",
      items: "عناصر",
      edit: "تعديل",
      itemsTotal: "مجموع العناصر",
      delivery: "التوصيل",
      notAvailable: "غير متاح",
      free: "مجاناً",
      discount: "خصم",
      total: "الإجمالي",
      shipping: "الشحن",
      contact: "الاتصال",
      shippingAddress: "عنوان الشحن",
      selectedAddress: "العنوان المحدد",
      change: "تغيير",
      billing: "الفواتير",
      promotionalCode: "رمز الترويجي",
      greatCouponCodeApplied: "رائع! تم تطبيق رمز القسيمة",
      paymentType: "طريقة الدفع",
      placeOrder: "إتمام الطلب",
      billingAddress: "عنوان الفواتير",
      name: "الاسم",
      flatHouseApNo: "رقم الشقة/المنزل/الشقة",
      addressLineFirst: "العنوان الأول",
      addressLineSecond: "العنوان الثاني",
      state: "الولاية",
      select: "اختيار",
      city: "المدينة",
      country: "البلد",
      indiaLabel: "الهند",
      pinCode: "الرمز البريدي",
      phoneNumber: "رقم الهاتف",
      saveAddress: "حفظ العنوان",
      setAsYourDefaultAddress: "اجعلها كعنوان تسليم افتراضي",
      transactionFailedRoute: "/فشل-المعاملة",
      orderPlacedRoute: "/تم-وضع-الطلب",
      addressBook: "دفتر العناوين",
      editAddress: "تعديل العنوان",
      noAddressIsAvailable: "لا يوجد عنوان متاح حاليًا.",
      addNewAddress: "إضافة عنوان جديد",
      useSelectedAddress: "استخدام العنوان المحدد",
      loginRoute: "/تسجيل-الدخول",
      releaseProductsRoute: "/إصدار-المنتجات",
      checkAvailabilityRoute: "/تحقق-من-التوفر",
      inr: "INR",
      somethingWentWrong: "حدث خطأ ما",
      emailAddressSuccessfullyAdded: "تمت إضافة عنوان البريد الإلكتروني بنجاح.",
      allProductsAreAvailable: "جميع المنتجات متوفرة.",
      sorryDeliveryNotAvailable: ".عذرا ، التسليم حاليا غير متوفر لهذا الموقع",
      subscription: "اشتراك",
      time9amto12pm: "9 صباحًا حتى 12 ظهرًا",
      time6amto9am: "6 صباحًا حتى 9 صباحًا",
      morning: "صباح",
      evening: "مساء",
      months: "أشهر",
      month: "شهر",
      cannotSetLessThanOneQty: "لا يمكنك تعيين كمية أقل من 1",
      cannotAddMoreThan: "لا يمكنك إضافة أكثر من",
      qtyOfThisProduct: "كمية من هذا المنتج",
      proceedToCheckout: "المتابعة إلى الدفع",
      gotADiscount: "هل لديك رمز خصم؟ قم بإضافته في الخطوة التالية",
      itemHasBeenAddedToWishlist: "تمت إضافة العنصر إلى قائمة الرغبات",
      itemAddedToWishlist: "تمت إضافة العنصر إلى قائمة الرغبات",
      maybePresentInWishlist: "لا يمكن الإضافة ، ربما موجود بالفعل في قائمة الرغبات",
      pleaseEnterEmailLabel: "يرجى إدخال عنوان البريد الإلكتروني لإتمام الطلب",
      removeCoupon: "إزالة الكوبون",
      placeOrderConfirmation: "هل أنت متأكد أنك تريد تأكيد الطلب؟",
      enterYourPromotionalCode: "أدخل رمز العرض الخاص بك",
      emailAddress: "عنوان البريد الإلكتروني",
      sameAsShippingAddress: "نفس عنوان الشحن",
      useDifferentAddress: "استخدم عنوانًا مختلفًا",
      debitCardCreditCardNetBanking: "بطاقة الخصم / الائتمان / الخدمات المصرفية عبر الإنترنت",
      cashOnDelivery: "الدفع عند الاستلام",
      upiMethod: "طريقة UPI",
      movetoWishlist: "انتقل إلى قائمة الرغبات",
      cartEmpty: "السلة فارغة",
      cartEmptyDescription: "لم تقم بإضافة أي عناصر إلى سلة التسوق الخاصة بك. استعرض المنتجات وأضفها إلى السلة.",
      browseProducts: "تصفح المنتجات",
      checkoutRouteLeavingGuardModalHeader: "هل أنت متأكد أنك تريد مغادرة هذه الصفحة؟",
      checkoutRouteLeavingGuardModalBody: "إذا غادرت هذه الصفحة ، فستتم إزالة رمز القسيمة الخاص بك.",
      checkOut: "الدفع",
      pleaseEnterAValidEmailAddress: "الرجاء إدخال عنوان بريد إلكتروني صالح.",
      emailIsRequired: "البريد الإلكتروني مطلوب.",
      yourCartIsUpdated: "تم تحديث سلة التسوق الخاصة بك نظرًا لتغيير بعض معلومات المنتجات. يُرجى التحقق من سلة التسوق الخاصة بك ثم المتابعة مرة أخرى.",
    },
    footer: {
      downloadAppFooter: "تحميل التطبيق",
      contactUs: "اتصل بنا",
      sendAMessage: "إرسال رسالة",
      chatOnWhatsapp: "الدردشة على الواتساب",
      aboutUs: "من نحن",
      customerReviews: "آراء العملاء",
      faqs: "الأسئلة الشائعة",
      social: "وسائل التواصل الاجتماعي",
      information: "معلومات",
    },
    header: {
      loginNSignup: "تسجيل الدخول / التسجيل",
      shop: "متجر",
      newArrivals: "جديد",
      aboutUs:  "من نحن",
      contactUs: "اتصل بنا",
      wishlist: "قائمة الرغبات",
      myOrders: "طلباتي",
      savedAddresses: "العناوين المحفوظة",
      connectedAccounts: "الحسابات المتصلة",
      helpCenters: "مركز المساعدة",
      notification: "الإشعارات",
      logout: "تسجيل الخروج",
      faqs: "الأسئلة الشائعة",
      welcomeToStore: "مرحبا بكم في المتجر",
      home: "الرئيسية",
    },
    upiPayment: {
      modalTitle: 'امسح رمز الاستجابة السريعة هذا',
      upiId: 'معرف UPI:',
      phoneNumber: 'رقم الهاتف:',
      close: 'إغلاق',
      nextStep: 'الخطوة التالية',
      goBack: 'العودة',
      confirm: 'تأكيد',
      infoDescription:
      'امسح رمز الاستجابة السريعة باستخدام تطبيقات UPI مثل BHIM و Paytm و Google Pay و PhonePe أو أي تطبيق UPI مصرفي آخر لإجراء الدفع لهذا الطلب. يرجى تأكيد على الشاشة التالية بعد أن تتم عملية الدفع بنجاح.',
      infoDescription2:
      'بمجرد التحقق من استلام الدفعة ، سيتم معالجة طلبك. شكرًا لك',
      confirmModalTitle: 'قم بتأكيد الدفع الخاص بك!',
      confirmModalDesc:
      'انقر فوق تأكيد فقط بعد خصم المبلغ من حسابك. سنقوم بالتحقق يدويًا من عملية الدفع الخاصة بك. هل أنت متأكد؟',
    },
    wishlist: {
      noWishlist: "لا يوجد قائمة رغبات",
      youHaveNotWishlist: "لم تقم بإضافة أي عناصر إلى قائمة الرغبات، استعرض المنتجات وأضفها إلى القائمة",
      browseProducts: "تصفح المنتجات",
      wishlistItem: "إضافة إلى قائمة الرغبات",
      theItemHasBeenRemovedFromTheWishlist: "تمت إزالة العنصر من قائمة الرغبات",
      currency: "INR",
      off: "% خصم",
      goToCart: "الانتقال إلى السلة",
      addToCart: "أضف إلى السلة",
      outOfStock: "نفذت الكمية",
    }
  },
  errors: {
    404: "الصفحة غير موجودة",
    somethingWentWrong: "هناك خطأ ما!",
    sorryYouCanNotResetYourPasswordAsYourAccountIsNotVerifiedPleaseDoSignupAgain: ".عذرًا ، لا يمكنك إعادة تعيين كلمة المرور الخاصة بك حيث لم يتم التحقق من حسابك. من فضلك قم بالتسجيل مرة أخرى",
    sorryYouNeedToConfirmYourAccountFirst: "!!.عذرا ، تحتاج إلى تأكيد حسابك أولا",
    noOrderRecordFound: "لم يتم العثور على سجل الطلب.",
  },
  notifications: {
    success: "نجاح",
    error: "خطأ",
    warning: "تحذير",
    info: "معلومات",
    deleted: "تم الحذف",
  },
  global: {
    noProductsFound: "لم يتم العثور على منتجات.",
    viewAll: "عرض الكل",
    cancel: "إلغاء",
    remove: "إزالة",
    yesConfirm: "نعم، تأكيد",
    home: "الصفحة الرئيسية",
    profile: "الملف الشخصي",
    ok: "موافق",
    okay: "حسنًا",
    submit: "إرسال",
    select: "اختيار",
    goToHome: "الانتقال إلى الصفحة الرئيسية",
    save: "حفظ",
    edit: "تعديل",
    cart: "السلة",
    apply: "تطبيق",
    shop: "تسوق",
    loading: "...جار التحميل",
    dismiss: "رفض",
    confirm: "تأكيد",
    close: "إغلاق",
  }
};

export default ar
