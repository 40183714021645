import { BlockComponent } from "../../../framework/src/BlockComponent";
import { RouteProps } from "react-router-dom";
// Customizable Area Start
// Customizable Area End

export interface Props extends RouteProps {
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    token: string;
    loading: boolean;
    // Customizable Area End
}
interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

class ProtectedRouteController extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        this.state = {
            token: "",
            loading: true,
        };
    }
    async componentDidMount() {
        let token = await window.localStorage.getItem("token");
        if (token) {
            this.setState({
                token,
                loading: false,
            });
        } else {
            this.setState({
                loading: false,
            });
        }
    }
    // Customizable Area End
}

export default ProtectedRouteController