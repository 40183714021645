import React, { Component } from "react";
import { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
const LoginScreen = lazy(() => import("../../email-account-login/src/LoginScreen.web"));
const ForgotPassword = lazy(() => import("../../forgot-password/src/ForgotPassword.web"));
const HelpCenter = lazy(() => import("../../helpcenter/src/HelpCenter.web"));
const AboutUs = lazy(() => import("../../contactus/src/AboutUs.web"));
const Profilebio = lazy(() => import("../../profilebio/src/index.web"));
const OTPConfirmation = lazy(() => import("../../otp-input-confirmation/src/OTPConfirmation.web"));
const ShoppingCart = lazy(() => import("../../shoppingcart/src/ShoppingCart.web"));
const ContactusScreen = lazy(() => import("../../contactus/src/ContactUs.web"));
const HomeDashboard = lazy(() => import("../../dashboard/src/Dashboard.web"));
const ProductDetails = lazy(() => import("../../dashboard/src/ProductDetails.web"));
const OrderDetailsBlog = lazy(() => import("../../orderdetailview/src/OrderDetails.web"));
const FilterOptions = lazy(() => import("../../filteritems/src/Filteroptions.web"));
const Checkout = lazy(() => import("../../shoppingcart/src/Checkout.web"));
const Header = lazy(() => import("../../studio-store-ecommerce-components/src/AppHeader/"));
const Footer = lazy(() => import("../../studio-store-ecommerce-components/src/AppFooter"));
const OrderPlaced = lazy(() => import("../../ordersummary/src/orderPlaced.web"));
const TransactionFailed = lazy(() => import("../../ordersummary/src/TransactionFailed.web"));
import ProtectedRoute from "./protectedRoute.web";
import "./App.css";

import Loader from "../../studio-store-ecommerce-components/src/AppLoader/AppLoader.web";

declare global {
  interface Window {
    notify: (notificationArray: any[]) => void;
  }
}

class WebRoutes extends Component {
  render() {
    localStorage.removeItem("headerTitle");
    let STORE_BASE_PATH_NAME = "/";

    try {
      STORE_BASE_PATH_NAME = new URL(`${process.env.REACT_APP_BASE_URL}`).pathname ?? "";
    } catch {}

    return (
      <BrowserRouter basename={STORE_BASE_PATH_NAME}>
        <Suspense
          fallback={
            <div style={{ height: 80 }}>
              <Loader loading={true} />
            </div>
          }
        >
          <Header />
          <div className="flex-grow-1">
            <Switch>
              <Route path="/" exact render={(props: any) => <HomeDashboard {...props} />} />
              <Route path="/login" exact render={(props: any) => <LoginScreen {...props} />} />
              <Route path="/forgotpassword" exact render={(props: any) => <ForgotPassword {...props} />} />
              <Route path="/home-page" exact render={(props: any) => <HomeDashboard {...props} />} />
              <ProtectedRoute path="/otpconfirm" exact render={(props: any) => <OTPConfirmation {...props} />} />
              <Route path="/help-center" exact render={(props: any) => <HelpCenter {...props} />} />
              <Route path="/help-center/:slug" exact render={(props: any) => <HelpCenter {...props} />} />
              <Route path="/aboutus" exact render={(props: any) => <AboutUs {...props} />} />
              <ProtectedRoute path="/contact-us" exact render={(props: any) => <ContactusScreen {...props} />} />
              <ProtectedRoute path="/filterOptions" render={(props: any) => <FilterOptions {...props} />} />
              <ProtectedRoute path="/profile/myorder/:orderId/:itemId" exact render={(props) => <OrderDetailsBlog {...props} />} />
              <ProtectedRoute path="/cart" exact render={(props: any) => <ShoppingCart {...props} />} />
              <ProtectedRoute path="/shop/:id" exact render={(props: any) => <ProductDetails {...props} />} />
              <ProtectedRoute path="/checkout" exact render={(props: any) => <Checkout {...props} />} />
              <ProtectedRoute path="/order-placed" exact render={(props: any) => <OrderPlaced {...props} />} />
              <ProtectedRoute path="/transactionfailed" exact render={(props: any) => <TransactionFailed {...props} />} />
              <ProtectedRoute path="/profilebio" render={(props: any) => <Profilebio {...props} />} />
              <Route path="*" render={() => <Redirect to="/home-page" />} />
            </Switch>
          </div>
          <Footer />
        </Suspense>
      </BrowserRouter>
    );
  }
}

export default WebRoutes;
