import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import en from "./locale/en.js"
import ar from "./locale/ar.js"

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: "en",
    partialBundledLanguages: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    saveMissing: false,
    react: {
      bindI18n: "languageChanged editorSaved",
      useSuspense: false, // Not compatible with SSR
    },
  })

  i18n.addResourceBundle('en', 'local', en, true, true);
  i18n.addResourceBundle('ar', 'local', ar, true, true);

export default i18n
