export const formatter = new Intl.Collator("en");

export const localeSort = (a: string, b: string) => formatter.compare(a, b)

export const localeSortBy = (key: string) => (a: any, b: any) => localeSort(a[key], b[key])

export const localeSortByDesc = (key: string) => (a: any, b: any) => localeSort(b[key], a[key])

export const localeSortByProp = (key: string) => (a: any, b: any) => localeSort(a.props[key], b.props[key])

export const localeSortByPropDesc = (key: string) => (a: any, b: any) => localeSort(b.props[key], a.props[key])

export const localeSortByPropValue = (key: string) => (a: any, b: any) => localeSort(a.props[key].value, b.props[key].value)

export const localeSortByPropValueDesc = (key: string) => (a: any, b: any) => localeSort(b.props[key].value, a.props[key].value)

export const localeSortFn = (arr: any[], key: string, desc: boolean) => {
  const sortFn = desc ? localeSortByDesc(key) : localeSortBy(key)
  return arr.sort(sortFn)
}

export const localeSortByPropFn = (arr: any[], key: string, desc: boolean) => {
  const sortFn = desc ? localeSortByPropDesc(key) : localeSortByProp(key)
  return arr.sort(sortFn)
}

export const localeTime = (time: number) => {
  const date = new Date(time)
  return date.toLocaleTimeString()
}

export const localeDate = (time: number) => {
  const date = new Date(time)
  return date.toLocaleDateString()
}

export const getLocaleEndpoint = (endpoint: string, lang: string) => {
  const endpointLang = lang || "en"
  if (endpoint?.includes("?")) {
    return endpoint + `&lang=${endpointLang}`
  }
  return endpoint + `?lang=${endpointLang}`
}

export const getLocaleDateAndTime = (date: string, locale: string, options = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: true
} as Intl.DateTimeFormatOptions) => {
  if (typeof date !== 'string') {
    return date
  }
  return new Date(date).toLocaleString(locale, options)
}