const en = {
  components: {
    homePage: {
      viewAll: "View all",
      categories: "Categories",
      newArrivals: "New Arrivals",
      trendingProducts: "Trending Products",
      copyright: "Copyright © 2020 Studio Store, USA. All rights reserved.",
      headerShop: "Shop",
      headerNewArrivals: "New Arrivals",
      searchPlaceholder: "Search",
      noResultsFound: "No Results Found",
      tryModifyingYourSearchToGetRelevantResults: "Try modifying your search to get relevant results.",
      goToMyAccount: "Go to My Account",
      myWishlist: "My wishlist",
      myOrders: "My Orders",
      profileAndSecurity: "Profile & Security",
      myAddresses: "My Addresses",
      helpCenter: "Help Center",
      socialAccounts: "Social Accounts",
      signOut: "Sign Out",
      notifications: "Notifications",
    },
    dashboard: {
      description: "Description",
      addToCart: "Add to Cart",
      goToCart: "Go to Cart",
      subscribed: "Subscribed",
      subscribe: "Subscribe",
      buyNow: "Buy Now",
      inStockOnline: "In stock online",
      soldOut: "Sold Out",
      weLlNotifyYou: "We’ll notify you!",
      weLlNotifyYouMessage:
        "We have received your request, as soon as this product is back on stock we’ll send you an email",
      price: "Price",
      quantity: "Quantity",
      outOfStock: "Out of stock",
      off: "% Off",
      updateCart: "Update Cart",
      totalPrice: "Total Price",
      home: "Home",
      shop: "Shop",
      viewAll: "View All",
      similarProducts: "Similar Products",
      notifyMe: "Notify me when available",
      reviews: "Reviews",
      willNotify: "You'll get notified once the product is back in stock",
    },
    filterItems: {
      clear: "Clear",
      price: "Price",
      noResultsFound: "No Results Found",
      updateOrRemoveSomeOfTheFiltersYouUsed: "Update or remove some of the filters you used",
      min: "Min",
      max: "Max",
      enterMinimumValue: "Enter Minimum Value",
      enterMaximumValue: "Enter Maximum Value",
      apply: "Apply",
      discounts: "Discounts",
      discountedItems: "Discounted Items",
      clearAll: "Clear All",
      kg: "kg",
      material: "Material",
      size: "Size",
      color: "Color",
      brand: "Brand",
      category: "Category",
      allProduct: "All Product",
      lowtoHigh: "Low to high",
      hightoLow: "High to low",
      byPopularity: "By Popularity",
      byNewest: "By Newest",
      recommended: "Recommended",
      sort: "Sort by",
      home: "Home",
      shop: "Shop",
      itemAddedToWishlist: "The item has been added to the wishlist",
      itemRemovedFromWishlist: "Item removed from wishlist",
      maximumValueShouldBeGreaterThanMinimumValue:
        "Maximum value should be greater than Minimum value",
      minimumAndMaximumShouldNotBeSame: "Minimum and Maximum should not be same!",
      loadingProducts: "Loading products...",
      filter: "Filter",
      searchColor: "Search Color",
    },
    scheduling: {
      addToCart: "Add to Cart",
      goToCart: "Go to Cart",
      subscribed: "Subscribed",
      subscribe: "Subscribe",
      buyNow: "Buy Now",
      price: "Price",
      quantity: "Quantity",
      outOfStock: "Out of stock",
      off: "% Off",
      updateCart: "Update Cart",
      totalPrice: "Total Price",
      subscription: "Subscription",
      subscriptionPeriod: "Select Subscription Period",
      subscriptionTimeslot: "Preferred Time Slot",
      packageIsRequired: "Package is required",
      periodIsRequired: "Period is required",
      timePeriodIsRequired: "Time period is required",
      timeSlotIsRequired: "Time slot is required",
    },
    login: {
      signIn: "Sign In",
      login: "Log In",
      continueVia: "Continue via ",
      bySigningIn: "By Signing In you agree with our",
      termNcondition: "Terms & Conditions",
      privacyPolicy: "Privacy Policy",
      getStarted: "Get started and discover the best offers around you",
      forgotPassword: "Forgot Password ?",
      signup: "Sign Up",
      emailPlaceholder: "Email",
      emailPhonePlaceholder: "Email / Phone Number",
      passwordPlaceholder: "Password",
      nameIsTooShort: "Name is too short",
      nameIsRequired: "Name is required",
      enterValidEmail: "Please enter a valid email address",
      emailIsRequired: "Email is required",
      passwordValidation:
        "Password must contain at least a capital letter, a lowercase letter, a number and a special character and min 8 characters",
      passwordIsRequired: "Password is Required",
      invalidEmailPhoneNumber: "Invalid Email / Phone Number",
      emailPhoneNumberIsRequired: "Email / Phone Number is required",
      errorOccurredWhileConnectingToFacebook:
        "Error occurred while connecting to Facebook, please try again later",
      errorOccurredWhileConnectingToGoogle:
        "Error occurred while connecting to Google, please try again later",
      sorryYouNeedToConfirmYourAccountFirst: "Sorry, You need to confirm your account first.",
      minimumPasswordLengthIs8: "Minimum Password length is 8",
      maximumPasswordLengthIs_16: "Maximum Password length is 16",
      passwordShouldIncludeAtLeastOneCapitalLetter:
        "Password should include at least one capital letter",
    },
    register: {
      bySigningIn: "By Signing In you agree with our",
      termNcondition: "Terms & Conditions",
      privacyPolicy: "Privacy Policy",
      getStarted: "Get started and discover the best offers around you",
      signupVia: "or Sign up via",
      passwordSuggestionMsg: "Enter a passsword with alphabets A-z, numbers 0-9 and a symbols",
      signup: "Sign Up",
      fullNamePlaceholder: "Full Name",
      minimumPasswordLengthIs8: "Minimum Password length is 8",
      emailPlaceholder: "Email",
      emailPhonePlaceholder: "Email / Phone Number",
      passwordPlaceholder: "Password",
      nameIsTooShort: "Name is too short",
      nameIsRequired: "Name is required",
      enterValidEmail: "Please enter a valid email address",
      emailIsRequired: "Email is required",
      passwordValidation:
        "Password must contain at least a capital letter, a lowercase letter, a number and a special character and min 8 characters",
      passwordIsRequired: "Password is Required",
      invalidEmailPhoneNumber: "Invalid Email / Phone Number",
      emailPhoneNumberIsRequired: "Email / Phone Number is required",
      errorOccurredWhileConnectingToFacebook:
        "Error occurred while connecting to Facebook, please try again later",
      errorOccurredWhileConnectingToGoogle:
        "Error occurred while connecting to Google, please try again later",
    },
    connectedAccounts: {
      areYouSureYouWantToDisconnectYour: "Are you sure you want to disconnect your",
      accountFrom: "account?",
      errorOccurredFacebook: "Error Occured While connecting to Facebook",
      errorOccurredGoogle: "Error Occured While connecting to Google",
      somethingWentWrong: "Something went wrong",
      addedGoogleAccount: "Google account added successfully.",
      addedFacebookAccount: "Facebook account added successfully.",
      removedGoogleAccount: "Google account removed successfully.",
      removedFacebookAccount: "Facebook account removed successfully.",
      cancel: "Cancel",
      disconnect: "Disconnect",
      connectedAs: "Connected as",
      connectFacebook: "Connect Facebook Account",
      connectGoogle: "Connect Google Account",
    },
    contactUs: {
      errorTitle: "Error",
      errorAllFieldsAreMandatory: "All fields are mandatory.",
      errorEmailNotValid: "Email not valid.",
      phoneNumberError: "Phone number not valid",
      nameTitle: "Name",
      emailTitle: "Email",
      numberTitle: "Phone number",
      commentsTitle: "Comments",
      namePlaceHolder: "Name",
      emailPlaceHolder: "Email",
      numberPlaceHolder: "Phone number",
      commentsPlaceHolder: "Comments",
      labelTitleText: "Contact us",
      labelBodyText: "Contact us body",
      btnExampleTitle: "CLICK ME",
      home: "Home",
      aboutUs: "About Us",
      customerReviews: "Customer reviews",
      contactUs: "Contact Us",
      biggerSign: ">",
      somethingWentWrong: "Something went wrong",
      readLess: "Read Less",
      readMore: "Read More",
      whatOurCustomersSay: "What our customers say...",
      successBlockTitle: "Message Sent Successfully",
      successBlockMessage: "We will connect with you soon regarding your query.",
      subjectTitle: "Subject",
      messageTitle: "Message",
      send: "Send",
      writeYourMessageHere: "Write your message here...",
      goToShop: "Go To Shop",
      goToHome: "Go To Home",
      minimum_3CharactersAreRequired: "Minimum 3 characters are required",
      maximum_20CharactersAreAllowed: "Maximum 20 characters are allowed.",
      nameIsRequired: "Name is required.",
      onlyAlphabetsAreAllowed: "Only alphabets are allowed.",
      pleaseEnterAValidEmailAddress: "Please enter a valid email address.",
      emailIsRequired: "Email is required.",
      onlyNumbersAreAllowed: "Only numbers are allowed.",
      negativeNumbersAreNotAllowed: "Negative numbers are not allowed.",
      phoneCanTContainADecimal: "Phone can't contain a decimal.",
      phoneNumberIsMissing: "Phone Number is missing.",
      messageIsRequired: "Message is required.",
      titleIsRequired: "Title is required.",
      information: "Information",
    },
    forgotPassword: {
      skipAsGuest: "Skip & Continue as Guest",
      otpHasBeenSent: "OTP has been sent to your email id.",
      otpHasBeenSenPhone: "OTP has been sent to your Phone Number.",
      forgotPasswordContentTextWithPhone:
        "Enter your registered Email / Phone Number and we’ll sent you OTP to reset your password",
      forgotPasswordContentText:
        "Enter your registered Email and we’ll sent you OTP to reset your password",
      getStarted: "Get started and discover the best offers around you",
      emailPlaceholder: "Email",
      emailPhonePlaceholder: "Email / Phone Number",
      passwordPlaceholder: "Password",
      invalidEmailOrPhone: "Invalid Email / Phone Number.",
      invalidEmail: "Invalid Email.",
      emailMustBeValid: "Email must be a valid",
      emailOrPhoneMustBeValid: "Email / Phone Number must be a valid",
      pleaseEnterOTP: "Please Enter OTP",
      pleaseEnterMax6DigitOTP: "Please enter minimum 5 and maximum 6 digit OTP",
      invalidOTP: "OTP is Invalid",
      verifyOtp: "Verify OTP",
      sendOtp: "Send OTP",
      resendOTP: "Resend OTP",
      otpVerification: "OTP Verification",
      forgotPassword: "Forgot Password ?",
      createNewPassword: "Create New Password",
      newPasswordValidationMessage:
        "Password must contain atleast a capital letter, a lowercase letter, a number and a special character",
      passwordIsRequired: "Password is Required",
      newPasswordIsRequired: "New Password is Required",
      confirmPasswordIsRequired: "Confirm Password is Required",
      required: "Required",
      passwordShouldIncludeAtLeastOneCapitalLetter:
        "Password should include at least one capital letter",
      newPassword: "New Password",
      confirmPassword: "Confirm Password",
      updatePassword: "Update Password",
      tooShort: "Too Short",
      passwordsMustMatch: "Passwords must match",
    },
    helpCenter: {
      home: "Home",
      profile: "Profile",
      helpCenter: "Help Center",
      welcomeToTheHelpCenter: "Welcome to the Help Center, {{userName}} ",
      FAQs: "FAQs",
      information: "Information",
    },
    notifications: {
      deleteAll: "Delete All",
      readAll: "Read All",
      deleteNotificationTitleMessage: "Delete Notification",
      deleteNotificationContentMessage: "Are you sure you want to delete the notification ?",
      continueShoppingButton: "Continue Shopping",
      loadMore: "Load More",
      noNotificationsTitle: "No notifications yet !",
      noNotificationsMessage: "Browse for products or check out our latest additions.",
      cancel: "Cancel",
      yesDelete: "Yes, Delete",
      notificationReadSuccessfully: "Notification Read Successfully !!!",
      readNotificationIsPending: "Read Notification is Pending !!!",
      deleteNotification: "Delete Notification!",
    },
    orderDetailView: {
      subscription: "SUBSCRIPTION",
      totalSubscriptionPrice: "Total Subscription Price",
      cancelSubscription: "Cancel Subscription",
      cancelSubscriptionQuestion: "Are you sure you want to cancel subscription",
      yourOrderIs: "Your order is",
      quantity: "Quantity",
      new: "New",
      orderReadyToBePickedUp: "Order ready to be picked up",
      myOrders: "My Orders",
      orderDetails: "Order Details",
      orderNumber: "Order Number",
      shippingAddress: "Shipping Address",
      phoneNumber: "Phone Number",
      myDotTransitMe: "myDot transit-me",
      myDotSuccessMe: "myDot success-me",
      myDotCancelMe: "myDot cancel-me",
      month_one: "Month",
      month_other: "Months",
      packageInfo: "{{subscriptionPackage}} for {{subscriptionPeriod}}",
      noAddressFound: "No Address Found",
      placed: "Placed",
      payment_failed: "Payment Failed",
      payment_pending: "Payment Pending",
      confirmed: "Confirmed",
      delivered: "Delivered",
      cancelled: "Cancelled",
      refunded: "Refunded",
      returned: "Returned",
      in_cart: "In Cart",
      created: "Created",
      in_transit: "In Transit",
      processing: "Processing",
      orderStatus: "Order status",
      totalAmount: "Total Amount",
      orderedOn: "Ordered on",
    },
    orderManagement: {
      browseProducts: "Browse Products",
      noAnyOrder: "No orders found",
      browseItemNOrderIt: "You haven’t order any items, Browse items and order it",
      rateNReview: "Rate and Review",
      rateOurProduct: "Rate our Product",
      youCannotReview: "You can't review a product until you purchase it.",
      writeDetailedReviewForUs: "Write detailed review for us ..",
      areYouSureCancelOrder: "Are you sure you want to cancel order ?",
      cancelOrder: "Cancel Order",
      subscription: "SUBSCRIPTION",
      totalSubscriptionPrice: "Total Subscription Price:",
      writeAReview: "Write a Review",
      TotalAmount: "Total Amount",
      orderedOn: "Ordered on",
      orderNumber: "Order Number",
      morning: "Morning ",
      evening: "Evening ",
      month_one: "Month",
      month_other: "Months",
      thankYouForYourReview: "Thank you for your review",
      quantity: "Quantity",
      confirmed: "Confirmed",
      delivered: "Delivered",
      cancelled: "Cancelled",
      refunded: "Refunded",
      returned: "Returned",
      in_cart: "In Cart",
      created: "Created",
      in_transit: "In Transit",
      processing: "Processing",
      placed: "Placed",
      ratingIsRequired: "Rating is required.",
      commentIsRequired: "Comment is required.",
    },
    orderSummary: {
      couponCodeCanTBeEmpty: "Coupon code can't be empty",
      enterYourPromotionCode: "Enter your promotion code",
      discount: "Discount",
      orderSummary: "Order Summary",
      ohNoesTransactionFailed: "Oh Noes!, Transaction Failed",
      yourOrderWasDeclined: "Your order was declined!",
      changeThePaymentMethod: "Change the Payment Method",
      cancelTransaction: "Cancel transaction?",
      thankYou: "Thank You",
      orderPlacedSuccessfully: "Order placed successfully",
      continueShopping: "Continue shopping",
      viewOrder: "View order",
    },
    otpConfirmation: {
      verifyOTP: "Verify OTP",
      resendOTP: "Resend OTP",
      otpVerification: "OTP Verification",
      pleaseEnterOTP: "Please Enter OTP",
      pleaseEnterMax6DigitOTP: "Please enter minimum 5 and maximum 6 digit OTP",
      invalidOTP: "OTP is Invalid",
      otpHasBeenSent: "OTP has been sent to your email id.",
      otpHasBeenSenPhone: "OTP has been sent to your Phone Number.",
      sendOtp: "Send OTP",
      invalid_otp: "Please go back and try again.",
      getStarted: "Get started and discover the best offers around you",
      otpPlaceholder: "Enter OTP",
      fashionAndTrendComeTogather: "Fashion and Trend come togather",
      upto30Off: "Upto 30% off",
      shopNow: "Shop Now",
      onlyNumbersAreAllowed: "Only numbers are allowed",
      negativeNumbersAreNotAllowed: "Negative numbers are not allowed",
      numberCanTContainADecimal: "Number can't contain a decimal",
      minimum_5DigitsAreRequired: "Minimum 5 digits are required.",
      maximum_6DigitsAreAllowed : "Maximum 6 digits are allowed.",
      otpIsRequired: "OTP is required.",
    },
    payments: {
      cardDetails: "Card Details",
      cardNumber: "Card Number",
      cardHolderName: "Card Holder Name",
      expirationDate: "Expiration Date",
      cvv: "CVV",
      proceedToPay: "Proceed to Pay",
    },
    profileBio: {
      logout: "Logout",
      cancel: "Cancel",
      wantTologout: "Are you sure you want to logout from",
      notification: "Notification",
      helpCenter: "Help Center",
      connectedAccounts: "Connected Accounts",
      savedAddresses: "Saved Addresses",
      myOrders: "My Orders",
      wishlist: "Wishlist",
      loadMore: "Load More",
      edit: "Edit",
      delete: "Delete",
      addNewAddress: "Add New Address",
      noAddressesSaved: "No addresses saved !",
      addAddress: "Add an Address",
      noAddressesList: "No addresses have been saved to the address list yet !",
      name: "Name",
      flatHouseApartment: "Flat / House / Apartment No.",
      addressLine1: "Address Line 1",
      addressLine2: "Address Line 2",
      city: "City",
      state: "State",
      country: "Country",
      pinCode: "Pin Code",
      phoneNumber: "Phone Number",
      saveAddress: "Save Address",
      editAddress: "Edit Address",
      deleteAddress: "Delete Address",
      areYouSureDeleteAddress: "Are you sure you want to delete the address ?",
      yesDelete: "Yes, Delete",
      defaultAdreess: "Default Address",
      defaultaddressContent: "Your default address has been changed.",
      okay: "Okay",
      goToProfile: "Go to Profile",
      goBackNBrowse: "You can now go back and continue browsing products Enjoy Shopping !",
      passwordChangedSuccessfully: "Password changed successfully!",
      changePassword: "Change Password",
      reEnterNewPassword: "Re-enter new password",
      newPassword: "Enter new password",
      enterCurrentPassword: "Enter current password",
      enterPasswordWithAlpha: "Enter a passsword with alphabets A-z, numbers 0-9 and a symbols",
      saveProfile: "Save Profile",
      phoneNo: "Phone No",
      email: "Email",
      removePicture: "Remove Picture",
      editProfile: "Edit Profile",
      home: "Home",
      profile: "Profile",
      errorOccured: "Oh Noes!, Error Occured",
      nameIsTooShort: "Name is Too Short",
      maximum_20CharactersAreAllowed: "Maximum 20 Characters are allowed",
      nameIsRequired: "Name is Required",
      flatHouseApartmentNoIsRequired: "Flat/House/Apartment No. is Required",
      addressLine_1IsTooShort: "Address Line 1 is Too Short",
      addressLine_1IsRequired: "Address Line 1 is Required",
      addressLine_2IsTooShort: "Address Line 2 is Too Short",
      cityIsTooShort: "City is Too Short",
      cityIsRequired: "City is Required",
      stateIsRequired: "State is Required",
      pinCodeIsRequired: "Pin Code is Required",
      phoneNumberIsRequired: "Phone Number is Required",
      svgFileAreNotAllowed: ".svg file are not allowed",
      currentPasswordIsRequired: "Current Password is Required",
      minimumPasswordLengthIs_8: "Minimum Password length is 8",
      maximumPasswordLengthIs_16: "Maximum Password length is 16",
      passwordMustContainAtleastACapital:
        "Password must contain atleast a capital letter, a lowercase letter, a number and a special character.",
      newPasswordIsRequired: "New Password is Required",
      passwordsAreNotMatching: "Passwords are not matching",
      confirmPasswordIsRequired: "Confirm Password is Required",
      goToHome: "Go to Home",
    },
    shoppingCart: {
      qty: "Qty",
      orderSummary: "Order Summary",
      items: "items",
      edit: "Edit",
      itemsTotal: "Items total",
      delivery: "Delivery",
      notAvailable: "N/A",
      free: "FREE",
      discount: "Discount",
      total: "Total",
      shipping: "Shipping",
      contact: "Contact",
      shippingAddress: "Shipping Address",
      selectedAddress: "Selected Address",
      change: "Change",
      billing: "Billing",
      promotionalCode: "Promotional code",
      greatCouponCodeApplied: "Great! Coupon code applied",
      paymentType: "Payment Type",
      placeOrder: "Place Order",
      billingAddress: "Billing Address",
      name: "Name",
      flatHouseApNo: "Flat / House / Apartment No.",
      addressLineFirst: "Address Line 1",
      addressLineSecond: "Address Line 2",
      state: "State",
      select: "Select",
      city: "City",
      country: "Country",
      indiaLabel: "India",
      pinCode: "Pin Code",
      phoneNumber: "Phone Number",
      saveAddress: "Save Address",
      setAsYourDefaultAddress: "Set as your default delivery address",
      transactionFailedRoute: "/transactionfailed",
      orderPlacedRoute: "/order-placed",
      addressBook: "Address Book",
      editAddress: "Edit Address",
      noAddressIsAvailable: "No existing address is available right now.",
      addNewAddress: "Add New Address",
      useSelectedAddress: "Use Selected Address",
      loginRoute: "/login",
      releaseProductsRoute: "/release_products",
      checkAvailabilityRoute: "/check_availability",
      inr: "INR",
      somethingWentWrong: "Something went wrong",
      emailAddressSuccessfullyAdded: "Email address successfully,added.",
      allProductsAreAvailable: "All products are available.",
      sorryDeliveryNotAvailable: "Sorry, currently delivery is not available for this location.",
      subscription: "SUBSCRIPTION",
      time9amto12pm: "9am to 12pm",
      time6amto9am: "6am to 9am",
      morning: "Morning",
      evening: "Evening",
      months: "Months",
      month: "Month",
      cannotSetLessThanOneQty: "You can not set less than 1,quantity",
      cannotAddMoreThan: "You can not add more than",
      qtyOfThisProduct: "quantity of this product",
      proceedToCheckout: "Proceed to Checkout",
      gotADiscount: "Got a discount code? Add it in the next step,",
      itemHasBeenAddedToWishlist: "The item has been added to the wishlist",
      itemAddedToWishlist: "The item has been added to the wishlist",
      maybePresentInWishlist: "Could not add, maybe already present in wishlist",
      pleaseEnterEmailLabel: "Please Enter Email Address to place Order",
      removeCoupon: "Remove coupon",
      placeOrderConfirmation: "Are you sure want to place the order?",
      enterYourPromotionalCode: "Enter your promotional code",
      emailAddress: "Email address",
      sameAsShippingAddress: "Same as Shipping Address",
      useDifferentAddress: "Use different Address",
      debitCardCreditCardNetBanking: "Debit Card/ Credit Card / Net banking",
      cashOnDelivery: "Cash on Delivery",
      upiMethod: "UPI Method",
      movetoWishlist: "Move to Wishlist",
      cartEmpty: "Your cart is empty",
      cartEmptyDescription: "Looks like you haven’t added anything to your cart yet",
      browseProducts: "Browse Products",
      checkoutRouteLeavingGuardModalBody: "If you leave this page, your coupon code will be removed.",
      checkoutRouteLeavingGuardModalHeader: "Are you sure you want to leave this page?",
      checkOut: "Check Out",
      pleaseEnterAValidEmailAddress: "Please enter a valid email address",
      emailIsRequired: "Email is Required",
      yourCartIsUpdated: "Your cart is updated due to some products info are changed. Please check your cart then proceed again.",
    },
    footer: {
      downloadAppFooter: "Download App",
      contactUs: "Contact us",
      sendAMessage: "Send a Message",
      chatOnWhatsapp: "Chat on Whatsapp",
      aboutUs: "About us",
      customerReviews: "Customer Reviews",
      faqs: "FAQs",
      social: "Social",
      information: "Information",
      paymentInitiatedSuccessful: "Payment initiated successfull."
    },
    header: {
      loginNSignup: "Login & signup",
      shop: "Shop",
      newArrivals: "New Arrivals",
      aboutUs: "About Us",
      contactUs: "Contact Us",
      wishlist: "Wishlist",
      myOrders: "My Orders",
      savedAddresses: "Saved Addresses",
      connectedAccounts: "Connected Accounts",
      helpCenters: "Help Centers",
      notification: "Notification",
      logout: "Logout",
      faqs: "FAQs",
      welcomeToStore: "Welcome to Store",
      home: "Home",
    },
    upiPayment: {
      modalTitle: "Scan this QR Code",
      upiId: "UPI ID:",
      phoneNumber: "Phone Number:",
      close: "Close",
      nextStep: "Next Step",
      goBack: "Go Back",
      confirm: "Confirm",
      infoDescription:
        "Scan the QR Code with any UPI apps like BHIM, Paytm, Google Pay, PhonePe or any Banking UPI app to make the payment for this order. Kindly confirm on the next screen once the payment has been done successfully. ",
      infoDescription2:
        "Once we verify the receipt of the payment, your order will be processed. Thank you",
      confirmModalTitle: "Confirm your payment !",
      confirmModalDesc:
        "Click confirm, only after amount deducted from your account. We will manually verify your transaction, Are you sure ?",
    },
    wishlist: {
      noWishlist: "No Wishlist",
      youHaveNotWishlist: "You haven’t wishlisted any items, Browse items and wishlist it",
      browseProducts: "Browse Products",
      wishlistItem: "Wishlist Item",
      theItemHasBeenRemovedFromTheWishlist: "The item has been removed from the wishlist",
      currency: "INR",
      off: "% Off",
      goToCart: "Go to Cart",
      addToCart: "Add to Cart",
      outOfStock: "Out of Stock",
      okay: "Okay",
    },
  },
  errors: {
    404: "Page not found",
    somethingWentWrong: "Something went wrong!",
    sorryYouCanNotResetYourPasswordAsYourAccountIsNotVerifiedPleaseDoSignupAgain: "Sorry, You can not reset your password as your account is not verified. Please do signup again.",
    sorryYouNeedToConfirmYourAccountFirst: 'Sorry, You need to confirm your account first.!!',
    noOrderRecordFound: "No order record found.",
  },
  notifications: {
    success: "Success",
    error: "Error",
    warning: "Warning",
    info: "Info",
    deleted: "Deleted",
  },
  global: {
    noProductsFound: "No Products Found.",
    viewAll: "View all",
    cancel: "Cancel",
    remove: "Remove",
    yesConfirm: "Yes, Confirm",
    home: "Home",
    profile: "Profile",
    ok: "Ok",
    okay: "Okay",
    submit: "Submit",
    select: "Select",
    goToHome: "Go to Home",
    save: "Save",
    edit: "Edit",
    cart: "Cart",
    apply: "Apply",
    shop: "Shop",
    loading: "Loading...",
    dismiss: "Dismiss",
    confirm: "Confirm",
    close: "Close",
  },
}

export default en
