export enum MessageConstants {
  updateTemplate,
  updateTemplateData,
  reGenerateGuestToken,
  updateNotification,
  NotificationCount,
  UpdateCart,
  UpdateCartLen,
  updateHeaderNavigation,
  updateHeaderNavigationData,
  updateFaviconAndTitle,
  updateFaviconAndTitleData,
  updateTheme,
  updateThemeData,
  updateSmsIntegration,
  updateSmsIntegrationData,
  updateDashboardSections,
  updateDashboardSectionsData,
}

export const getMessageName = (myEnum: MessageConstants) => {
  return MessageConstants[myEnum];
};
